import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OccurancesService } from 'src/app/services/diseases/occurances.service';

@Component({
  selector: 'app-view-occurances',
  templateUrl: './view-occurances.component.html',
  styleUrls: ['./view-occurances.component.css']
})
export class ViewOccurancesComponent implements OnInit {

  public occurance: any;

  constructor(public occuranceService: OccurancesService, public router: Router) { }

  ngOnInit() {
    this.occurance = this.occuranceService.getViewPageData();
  }

  cancel() {
    this.router.navigate(['app/occurances']);
  }
}
