import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LivestockPermitService } from 'src/app/services/permits/livestock/livestock-permit.service';
import { LiveAnimalpermit } from 'src/app/types/permits';

@Component({
  selector: 'app-view-permit',
  templateUrl: './view-permit.component.html',
  styleUrls: ['./view-permit.component.css']
})
export class ViewPermitComponent implements OnInit {

  public dataSource: any;
  public component: string;
  public componentData: LiveAnimalpermit = {};
  public objection = false;
  public incoming = false;
  progress = false;
  progressAnimal = false;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public permit: LivestockPermitService
  ) { }
  displayedColumns: string[] = ['animal', 'quantity'];

  ngOnInit() {
    this.progressAnimal = true;
    this.component = this.permit.getViewPage();
    this.componentData = this.permit.getViewPageData();
    this.addPermitAnimals();
    if (this.component === 'no-objection') {
      this.objection = true;
    } else if (this.component === 'incoming') {
      this.incoming = true;
    }
  }

  addPermitAnimals(): void {
    this.permit.getPermitAnimals(this.componentData.permit_number).subscribe(
      (data) => {
       this.dataSource = data;
       this.progressAnimal = false;
      }, (err) => {
        alert(err.message);
        this.progressAnimal = false;
      }
    );
  }

  cancel(): void {
    this.router.navigate(['app/livestock-permits']);
  }

  close(): void {
    this.progress = true;
    this.permit.changePermitStatus('Accepted', this.componentData.permit_number).subscribe((data) => {
      this.progress = false;
      this.router.navigate(['app/livestock-permits']);
    }, (err) => {
      alert(err.message);
    });
  }

  accept(): void {
    this.progress = true;
    this.permit.changePermitStatus('Pending', this.componentData.permit_number).subscribe((data) => {
      this.progress = false;
      this.router.navigate(['app/livestock-permits']);
    }, (err) => {
      alert(err.message);
    });
  }

  reject(): void {
    this.progress = true;
    this.permit.rejectPermit(this.componentData.reject_reason, this.componentData.permit_number).subscribe((data) => {
      this.progress = false;
      this.router.navigate(['app/livestock-permits']);
    }, (err) => {
      alert(err.message);
    });
  }
}


