import { Component, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authentication/auth.service';

@Component({
  selector: 'app-silab-auth',
  templateUrl: './silab-auth.component.html',
  styleUrls: ['./silab-auth.component.css']
})
export class SilabAuthComponent implements OnInit {
  public loading = false;
  @Output() error = { message: null };
  errorr = false;
  auth = {
    username: null,
    password: null
  };
  token: any;
  constructor(public dialogRef: MatDialogRef<SilabAuthComponent>,
    public authService: AuthService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  close() {
    this.dialogRef.close(false);
  }

  login() {
    this.loading = true;
    this.errorr = false;
    this.authService.silaBAuth(JSON.stringify(this.auth)).subscribe((response) => {
      this.token = response;
      this.authService.setSilabToken(this.token.token);
     //this.router.navigate(['app/silab']);
      this.loading = false;
      this.dialogRef.close(true);
    }, (err) => {
      this.error.message = 'Check Credential And Try Again';
      this.loading = false;
      this.errorr = true;
    });
  }

  ngOnInit() {
  }

}
