import {
  Component,
  EventEmitter, OnInit,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangeEvent } from 'ngx-virtual-scroller';
import { from, Observable } from 'rxjs';
import { SilabAuthComponent } from '../dialogs/silab-auth/silab-auth.component';
import { OccurancesService } from '../services/diseases/occurances.service';
import { Occurance } from '../types/occurance';
@Component({
  selector: 'app-labs-list',
  templateUrl: './labs.list.component.html',
  styleUrls: ['./labs.list.component.css']
})
export class LabsListComponent implements OnInit {

  private limit = 50;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onItemSelected = new EventEmitter<Occurance>();

  currentId: number;
  buffer: Occurance[] = [];
  loading: boolean;
  searchText: string;
  occaranceData: Occurance[] = [];

  constructor(
    public occuranceService: OccurancesService,
    public dialog: MatDialog
  ) {
    this.getOccurances();
    this.refresh();
  }

  getOccurances() {
    this.occuranceService.getOccurances().subscribe(
      (data) => {
        this.occaranceData = data;
        this.refresh();
      }, (err) => {
        alert(err.message);
      }
    );
  }

  onListChange(event: ChangeEvent) {

    if (event.end !== this.buffer.length) {
      return;
    }

    this.loading = true;
    this.fetchNextChunk(this.buffer.length, this.limit)
      .subscribe(chunk => {
        this.buffer.push(chunk);
        this.loading = false;
      }, err => console.log(err), () => this.loading = false);

  }

  fetchNextChunk(fromIndex: number, limit: number): Observable<Occurance> {

    return from(this.occaranceData);

  }

  selectItem(item: any) {

    this.currentId = item.id;
    this.onItemSelected.emit(item);

  }

  isSelected(item: Occurance): boolean {

    return this.currentId && this.currentId === item.id;

  }

  public refresh(): void {

    this.loading = true;
    this.buffer = [];

    this.fetchNextChunk(0, this.limit)
      .subscribe(
        {
          next: response => this.buffer.push(response),
          error: err => this.loading = false,
          complete: () => this.loading = false
        }
      );

  }

  public openSilab() {
    const dialogRef = this.dialog.open(SilabAuthComponent, {
      width: '340px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  public setCurrentId(id: number): void {

    this.currentId = id;

  }

  ngOnInit(): void {
    this.getOccurances();
    this.refresh();
  }

}
