import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { DeleteComponent } from 'src/app/dialogs/delete/delete/delete.component';
import { LivestockPermitService } from 'src/app/services/permits/livestock/livestock-permit.service';
import { ReportsService } from 'src/app/services/reports.service';
import { LiveAnimalpermit } from 'src/app/types/permits';
import { Locations } from 'src/app/utils/locations';

@Component({
  selector: 'app-permit-report',
  templateUrl: './permit-report.component.html',
  styleUrls: ['./permit-report.component.css']
})
export class PermitReportComponent implements OnInit {
  // province and district data
  public centres = new Locations();
  public destProvince: any[];
  public destDistrict: any[];
  public data = {
    origin_province: null,
    origin_district: null,
    start_date: null,
    end_date: null,
    destination_district: null
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: any;
  private outgoin_permit: LiveAnimalpermit[];
  loading = false;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public reportServ: ReportsService,
    public permit: LivestockPermitService) { }

  displayedColumns: string[] = ['permit_number', 'name', 'number_of_animals', 'owner',
    'stock_inventory', 'destination_province', 'destination_district', 'status', 'actions'];

  ngOnInit() {
    this.destDistrict = this.centres.getDistricts();
    this.destProvince = this.centres.getProvinces();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  viewPermit(permit): void {
    this.router.navigate(['app/view-permit']);
    this.permit.setViewPage('outgoing');
    this.permit.setViewPageData(permit);
  }

  run(): void {
    this.loading = true;
    this.reportServ.getLiveStockMovement(this.data).subscribe((data) => {
      this.loading = false;
      console.log(data);
      this.dataSource = data;
      this.dataSource.paginator = this.paginator;
    }, (err) => {

    });
  }

}
