import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CattleMeatGradingComponent } from 'src/app/dialogs/cattle-meat-grading/cattle-meat-grading.component';
import { LiveCattleGradingComponent } from 'src/app/dialogs/live-cattle-grading/live-cattle-grading.component';
import { OtherMeatGradingComponent } from 'src/app/dialogs/other-meat-grading/other-meat-grading.component';

@Component({
  selector: 'app-animal-sheet',
  templateUrl: './animal-sheet.component.html',
  styleUrls: ['./animal-sheet.component.css']
})
export class AnimalSheetComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<AnimalSheetComponent>,
    public dialog: MatDialog) { }

  openCattle(): void {
    const dialogRef = this.dialog.open(CattleMeatGradingComponent, {
      width: '450px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openLiveCattle(): void {
    const dialogRef = this.dialog.open(LiveCattleGradingComponent, {
      width: '450px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openOther(animal): void {
    const dialogRef = this.dialog.open(OtherMeatGradingComponent, {
      width: '450px',
      data: {
        animal
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnInit() {
  }

}
