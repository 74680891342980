import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.router';
import { SharedModule } from './shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';


import { LabDetailComponent } from './lab-details/lab-detail.component';
import { LabsListComponent } from './lab-list/labs.list.component';
import { LabsComponent } from './labs/labs.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MapComponent } from './map/map.component';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { LandingComponent } from './landing/landing.component';
import { MeatComponent } from './grading/meat/meat/meat.component';
import { AnimalComponent } from './grading/animal/animal/animal.component';
import { AnimalSheetComponent } from './bottom-sheets/animal-sheet/animal-sheet.component';
import { CattleMeatGradingComponent } from './dialogs/cattle-meat-grading/cattle-meat-grading.component';
import { OtherMeatGradingComponent } from './dialogs/other-meat-grading/other-meat-grading.component';
import { LivestockPermitComponent } from './permits/livestock/livestock-permit/livestock-permit.component';
import { MeatPermitComponent } from './permits/meat/meat-permit/meat-permit.component';
import { OutgoingPermitComponent } from './permits/livestock/outgoing-permit/outgoing-permit.component';
import { IncomingPermitComponent } from './permits/livestock/incoming-permit/incoming-permit.component';
import { NoObjectionComponent } from './permits/livestock/no-objection/no-objection.component';
import { NewPermitComponent } from './permits/livestock/new-permit/new-permit.component';
import { ViewPermitComponent } from './permits/livestock/view-permit/view-permit.component';
import { CarcassMovementComponent } from './dialogs/carcass-movement/carcass-movement.component';
import { ViewCarcassMovementComponent } from './dialogs/view-carcass-movement/view-carcass-movement.component';
import { LiveCattleGradingComponent } from './dialogs/live-cattle-grading/live-cattle-grading.component';
import { DeleteComponent } from './dialogs/delete/delete/delete.component';
import { OccurancesComponent } from './disease/occurances/occurances.component';
import { ProfileComponent } from './dialogs/profile/profile.component';
import { RabiesComponent } from './disease/rabies/rabies.component';
import { SerologyComponent } from './disease/serology/serology.component';
import { SubmissionComponent } from './disease/submission/submission.component';
import { ViewOccurancesComponent } from './disease/view-occurances/view-occurances.component';
import { DashComponent } from './dash/dash.component';
import { ReportsComponent } from './reports/reports.component';
import { DiseaseComponent } from './dash/disease/disease.component';
import { PermitComponent } from './dash/permit/permit.component';
import { CarcassComponent } from './dash/carcass/carcass.component';
import { PermitReportComponent } from './reports/permit-report/permit-report.component';
import { DiseaseReportComponent } from './reports/disease-report/disease-report.component';
import { CarcassReportComponent } from './reports/carcass-report/carcass-report.component';
import { DeathReportComponent } from './reports/death-report/death-report.component';
import { SilabComponent } from './disease/silab/silab.component';
import { SilabAuthComponent } from './dialogs/silab-auth/silab-auth.component';
import { SilabResultDetailsComponent } from './dialogs/silab-result-details/silab-result-details.component';
import { SilabEmailsComponent } from './dialogs/silab-emails/silab-emails.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    HomeComponent,
    AuthComponent,
    MapComponent,
    DashboardComponent,
    LabsComponent,
    LabsListComponent,
    LabDetailComponent,
    MeatComponent,
    AnimalComponent,
    AnimalSheetComponent,
    CattleMeatGradingComponent,
    OtherMeatGradingComponent,
    LivestockPermitComponent,
    MeatPermitComponent,
    OutgoingPermitComponent,
    IncomingPermitComponent,
    NoObjectionComponent,
    NewPermitComponent,
    ViewPermitComponent,
    CarcassMovementComponent,
    ViewCarcassMovementComponent,
    LiveCattleGradingComponent,
    DeleteComponent,
    OccurancesComponent,
    ProfileComponent,
    RabiesComponent,
    SerologyComponent,
    SubmissionComponent,
    ViewOccurancesComponent,
    DashComponent,
    ReportsComponent,
    DiseaseComponent,
    PermitComponent,
    CarcassComponent,
    PermitReportComponent,
    DiseaseReportComponent,
    CarcassReportComponent,
    DeathReportComponent,
    SilabComponent,
    SilabAuthComponent,
    SilabResultDetailsComponent,
    SilabEmailsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    AnimalSheetComponent,
    CattleMeatGradingComponent,
    OtherMeatGradingComponent,
    CarcassMovementComponent,
    ViewCarcassMovementComponent,
    LiveCattleGradingComponent,
    DeleteComponent,
    ProfileComponent,
    SilabAuthComponent,
    SilabResultDetailsComponent,
    SilabEmailsComponent
  ]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry
      .registerFontClassAlias('fontawesome', 'fa')
      .registerFontClassAlias('mdi', 'mdi-set')
      .addSvgIcon('place_user_offline', sanitizer.bypassSecurityTrustResourceUrl('assets/placeholder-user-offline.svg'))
      .addSvgIcon('place_user_online', sanitizer.bypassSecurityTrustResourceUrl('assets/placeholder-user-online.svg'));
  }
}
