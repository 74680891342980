import { Component, OnInit, ViewChild } from '@angular/core';
import { Locations } from 'src/app/utils/locations';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { ViewCarcassMovementComponent } from 'src/app/dialogs/view-carcass-movement/view-carcass-movement.component';
import { CarcassPermitData } from '../../types/permits';
import { ReportsService } from 'src/app/services/reports.service';
@Component({
  selector: 'app-carcass-report',
  templateUrl: './carcass-report.component.html',
  styleUrls: ['./carcass-report.component.css']
})
export class CarcassReportComponent implements OnInit {
  // province and district data
  public centres = new Locations();
  public destProvince: any[];
  public destDistrict: any[];
  public data = {
    origin_province: null,
    origin_district: null,
    start_date: null,
    end_date: null,
    destination_district: null
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: any;
  carcass_permit: CarcassPermitData[];
  loading = false;

  constructor(public dialog: MatDialog, public reportServ: ReportsService) { }
  displayedColumns: string[] = ['permit_number', 'name', 'abattoir_name', 'to_establishment', 'actions'];

  ngOnInit() {
    this.destDistrict = this.centres.getDistricts();
    this.destProvince = this.centres.getProvinces();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  // view permit
  viewPermit(permit): void {
    const dialogRef = this.dialog.open(ViewCarcassMovementComponent, {
      width: '450px',
      data: {
        permit
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result === true) {

      } else {
        console.log(result);
      }

    });
  }

  run(): void {
    this.loading = true;
    this.reportServ.getCarcassMovement(this.data).subscribe((data) => {
      this.loading = false;
      console.log(data);
      this.dataSource = data;
      this.dataSource.paginator = this.paginator;
    }, (err) => {

    });
  }

}
