import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GradingService } from 'src/app/services/grading/grading.service';
import { OtherMeatGrade } from 'src/app/types/grading';

@Component({
  selector: 'app-other-meat-grading',
  templateUrl: './other-meat-grading.component.html',
  styleUrls: ['./other-meat-grading.component.css']
})
export class OtherMeatGradingComponent implements OnInit {

  public animal: string;
  public gradeData: OtherMeatGrade = {};
  public user_province: string;
  public user_district: string;
  public user_abbatoir: string;
  loading = false;
  public grades = [];

  constructor(public dialogRef: MatDialogRef<OtherMeatGradingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public auth: AuthService,
    public gradingService: GradingService
  ) { }

  ngOnInit() {
    this.animal = this.data.animal;
    this.getGrades();
    this.gradeData.animal = this.animal;
    this.user_province = this.auth.getSessionData().user.province;
    this.user_district = this.auth.getSessionData().user.district;
    // ! user centre name in production
    this.user_abbatoir = 'Rainham';
    this.gradeData.abattoir = this.user_abbatoir;
    this.gradeData.province = this.user_province;
    this.gradeData.district = this.user_district;
  }

  // get ma grades anoenderana ne animal that has been choosen
  getGrades(): void {
    switch (this.animal) {
      case 'goat':
        this.grades = ['Goat Super',
          'Goat Choice',
          'Goat Standard',
          'Goat Inferior'];
        break;
      case 'sheep':
        this.grades = [
          'Lamb Super',
          'Lamb Choice',
          'Lamb Standard',
          'Lamb Inferior',
          'Mutton Super',
          'Mutton Choice',
          'Mutton Standard',
          'Mutton Mutton',
          'Mutton Inferior'];
        break;
      case 'pig':
        this.grades = [
          'Under Porker (UP)',
          'Porker 1 (P1)',
          'Porker 2 (P2)',
          'Porker 3 (P3)',
          'Porker inferior',
          'Baconer Super Lean (BSL)',
          'Baconer 1 (B1)',
          'Baconer 2 (B2)',
          'Baconer 3 (B3)',
          'General Purpose 1 (GP1)',
          'General Purpose 2 (GP2)',
          'General Purpose 3 (GP3)',
          'A Manufacturer (AM)',
          'B Manufacturer (BM)',
          'Inferior Manufacturer (Minf)'];
        break;

      default:
        break;
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  add(): void {
    this.loading = true;
    this.gradingService.otherMeatGrading(this.gradeData).subscribe((data) => {
      this.loading = false;
      this.dialogRef.close(true);
    }, (err) => {

    });
  }

}
