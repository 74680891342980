import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OccurancesService } from 'src/app/services/diseases/occurances.service';

@Component({
  selector: 'app-silab-emails',
  templateUrl: './silab-emails.component.html',
  styleUrls: ['./silab-emails.component.css']
})
export class SilabEmailsComponent implements OnInit {

  public emailData: any;
  public receipient1 = {};
  public receipient2 = {};
  public receipient3 = {};
  public loading = false;

  constructor(public dialogRef: MatDialogRef<SilabEmailsComponent>,
    public occuranceService : OccurancesService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.data.data.recipients = [];
    this.emailData = this.data.data;
  }

  send() {
    this.loading = true;

    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
    if (!isEmpty(this.receipient1)) {
      this.emailData.recipients.push(this.receipient1);
    }

    if (!isEmpty(this.receipient2)) {
      this.emailData.recipients.push(this.receipient2);
    }

    if (!isEmpty(this.receipient3)) {
      this.emailData.recipients.push(this.receipient3);
    }

    this.occuranceService.emailResults(this.emailData).subscribe((data)=>{ 
      this.dialogRef.close(true);
      this.loading = false;
    },(error)=>{
      this.loading = false;
      this.dialogRef.close(true);
    });

  }

  cancel() {
    this.dialogRef.close(false);
  }

}
