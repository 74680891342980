import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  MatChipsModule,
  MatButtonToggleModule,
  MatTabsModule
} from '@angular/material';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatNativeDateModule } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ApolloModule } from 'apollo-angular';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { Ng2Webstorage } from 'ngx-webstorage';
import { CommonModule } from '@angular/common';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { MessageComponent } from './message/message.component';
import { NotFoundComponent } from './notfound/notfound.component';
import { LoaderComponent } from './loader/loader.component';
import { MatBadgeModule } from '@angular/material/badge';
import {MatRadioModule} from '@angular/material/radio';


const components = [
  MessageComponent,
  NotFoundComponent,
  LoaderComponent
];


@NgModule({
  imports: [
    CommonModule,
    MatRadioModule,
    MatBadgeModule,
    FlexLayoutModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatProgressBarModule,
    FormsModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatRippleModule,
    MatTabsModule,
    HttpClientModule,
    MatGridListModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    Ng2Webstorage,
    VirtualScrollerModule,
    MatBottomSheetModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    CommonModule,
    MatRadioModule,
    MatBadgeModule,
    FlexLayoutModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatProgressBarModule,
    FormsModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatChipsModule,
    MatRippleModule,
    MatTabsModule,
    HttpClientModule,
    MatGridListModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    Ng2Webstorage,
    VirtualScrollerModule,
    MatBottomSheetModule,
    ...components
  ],
  entryComponents: []
})
export class SharedModule { }
