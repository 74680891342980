import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings } from 'src/app/utils/settings';
import { AuthService } from './../authentication/auth.service';
import { Occurance } from '../../types/occurance';


@Injectable({
  providedIn: 'root'
})
export class OccurancesService {

  private settings = new Settings();
  private url: String;
  private token: String;
  private headers: any;
  public viewpage: string;
  private user_province: string;
  private user_district: string;
  private occuranc: Occurance;

  constructor(public auth: AuthService, private http: HttpClient) {
    this.url = this.settings.getBASE_URL();
    this.token = this.auth.getSessionData().access_token;
    this.headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.token}`
    };
    this.user_province = this.auth.getSessionData().user.province;
    this.user_district = this.auth.getSessionData().user.district;
  }

  setViewPage(page): void {
    this.viewpage = page;
  }

  getViewPage(): string {
    return this.viewpage;
  }

  setViewPageData(data: Occurance): void {
    this.occuranc = data;
  }

  getViewPageData(): Occurance {
    return this.occuranc;
  }


  public newOccurance(occurance: Occurance): any {
    const URL = this.url + '/api/disease-occurance';
    return this.http.post(URL, occurance, { headers: this.headers });
  }

  public getOccurances(): any {
    const URL = this.url + `/api/disease-occurance`;
    return this.http.get(URL, { headers: this.headers });
  }

  public emailResults (emailData): any {
    const URL = this.url + '/api/auth/silab-results-notification';
    return this.http.post(URL, emailData, { headers: this.headers });
  }
}
