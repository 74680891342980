import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { LivestockPermitService } from 'src/app/services/permits/livestock/livestock-permit.service';
import { LiveAnimalpermit, PermitAnimals } from 'src/app/types/permits';
import { Locations } from 'src/app/utils/locations';

@Component({
  selector: 'app-new-permit',
  templateUrl: './new-permit.component.html',
  styleUrls: ['./new-permit.component.css']
})
export class NewPermitComponent implements OnInit {
  public dataSource: any;
  public permit: LiveAnimalpermit = {};
  public permitAnimal: PermitAnimals = {};
  public permitAnimalContainer: PermitAnimals[] = [];
  public loading = false;
  private user_province: string;
  private user_district: string;
  private user: string;
  public permitAnimalsList = [];
  public allPermitAnimalsSum = 0;

  // province and district data
  public centres = new Locations();
  public destProvince: any[];
  public destDistrict: any[];

  constructor(
    public router: Router,
    public auth: AuthService,
    public permitService: LivestockPermitService
  ) { }
  displayedColumns: string[] = ['animal', 'quantity', 'actions'];

  ngOnInit() {
    this.user_province = this.auth.getSessionData().user.province;
    this.user_district = this.auth.getSessionData().user.district;
    this.user = `${this.auth.getSessionData().user.name}  ${this.auth.getSessionData().user.surname}`;
    this.permit.createdby = this.user;
    this.destDistrict = this.centres.getDistricts();
    this.destProvince = this.centres.getProvinces();
    this.getDistrictsInProvince();
  }

  addPermitAnimals(): void {
    this.permitAnimalContainer.push(this.permitAnimal);
    this.permitAnimalTable();
  }

  permitAnimalTable() {
    this.dataSource = this.permitAnimalContainer;
    // apa we are refreshing the data source
    this.dataSource = [...this.dataSource];
    this.allPermitAnimalsSum = this.totalPermitAnimals(this.permitAnimalContainer, 'quantity');
    this.permitAnimal = {};
  }

  totalPermitAnimals(items, prop) {
    return items.reduce((a, b) => {
      return a + b[prop];
    }, 0);
  }

  deleteAnimal(element) {
    const index = this.permitAnimalContainer.indexOf(element, 0);
    if (index > -1) {
      this.permitAnimalContainer.splice(index, 1);
    }
    this.permitAnimalTable();
  }

  cancel(): void {
    this.router.navigate(['app/livestock-permits']);
  }

  save(): void {
    this.loading = true;
    this.permit.createdby = this.user;
    this.permit.origin_province = this.user_province;
    this.permit.origin_district = this.user_district;

    // apa pakuita ka maths kekuti kana pane no objection edit a few variables
    if (this.permit.no_objection === true) {
      this.permit.no_objection_by = this.user;
      this.permit.permit_status = 'Pending N-O Request';
    } else {
      this.permit.permit_status = 'Pending';
    }

    this.permitService.newPermit(this.permit).subscribe((data) => {
      this.loading = false;
      const permit_number = data.animalmovementpermit.permit_number;

      if (this.permitAnimalContainer.length !== 0) {
        this.permitService.addPermitAnimals(permit_number, this.permitAnimalContainer).subscribe(
          (animal) => {
            this.router.navigate(['app/livestock-permits']);
          }, (err) => {
            alert(err.message);
          }
        );
      } else {
        this.router.navigate(['app/livestock-permits']);
      }
    }, (err) => {
      this.loading = false;
      alert(err.message);
    });
  }

  // autogenerate list of animals to be added in list
  addAnimalTOList(evt, animal) {

    switch (animal) {
      case 'Goats':
        if (evt.checked === true) {
          this.permitAnimalsList.push('Goats');
        } else {
          const index = this.permitAnimalsList.indexOf(animal);
          if (index !== -1) {
            this.permitAnimalsList.splice(index, 1);
          }
        }
        break;
      case 'Sheep':
        if (evt.checked === true) {
          this.permitAnimalsList.push('Sheep');
        } else {
          const index = this.permitAnimalsList.indexOf(animal);
          if (index !== -1) {
            this.permitAnimalsList.splice(index, 1);
          }
        }
        break;
      case 'Pigs':
        if (evt.checked === true) {
          this.permitAnimalsList.push('Pigs');
        } else {
          const index = this.permitAnimalsList.indexOf(animal);
          if (index !== -1) {
            this.permitAnimalsList.splice(index, 1);
          }
        }
        break;
      case 'Donkey':
        if (evt.checked === true) {
          this.permitAnimalsList.push('Donkey');
        } else {
          const index = this.permitAnimalsList.indexOf(animal);
          if (index !== -1) {
            this.permitAnimalsList.splice(index, 1);
          }
        }
        break;
      case 'Cattle':
        if (evt.checked === true) {
          this.permitAnimalsList.push('Heifers ');
          this.permitAnimalsList.push('Bulls');
          this.permitAnimalsList.push('Calves');
          this.permitAnimalsList.push('Oxen');
          this.permitAnimalsList.push('Steers');
          this.permitAnimalsList.push('Cows');
        } else {
          const index = this.permitAnimalsList.indexOf(animal);
          if (index !== -1) {
            this.permitAnimalsList.splice(index, 1);
          }
        }
        break;
    }
  }

  // sot districts by province

  getDistrictsInProvince() : any[] {
    let newData = [];
    for(let i = 0 ; i < this.destDistrict.length ; i++){
      if(this.destDistrict[i].province == this.permit.destination_province){
        newData.push(this.destDistrict[i]);
      };
    }
    return newData;
  }

}
