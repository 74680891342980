import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { LivestockPermitService } from 'src/app/services/permits/livestock/livestock-permit.service';
import { LiveAnimalpermit } from 'src/app/types/permits';

@Component({
  selector: 'app-no-objection',
  templateUrl: './no-objection.component.html',
  styleUrls: ['./no-objection.component.css']
})
export class NoObjectionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() number_of_permits_event = new EventEmitter<number>();
  private no_permit: LiveAnimalpermit[];
  loading = false;
  public number_of_permits: number;


  public dataSource: any;
  constructor(
    public router: Router,
    public permit: LivestockPermitService
  ) { }

  displayedColumns: string[] = ['permit_number', 'name', 'origin_province', 'origin_district', 'actions'];

  ngOnInit() {
    this.geNOrequest();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  geNOrequest(): void {
    this.loading = true;
    this.permit.getNOPermits().subscribe((data) => {
      this.loading = false;
      this.no_permit = data;
      this.number_of_permits = this.no_permit.length;
      this.number_of_permits_event.emit(this.number_of_permits);
      this.dataSource = new MatTableDataSource(this.no_permit);
      this.dataSource.paginator = this.paginator;
    }, (err) => {
      alert(err.message);
    });
  }

  refresh() {
    this.geNOrequest();
  }

  viewPermit(permit): void {
    this.router.navigate(['app/view-permit']);
    this.permit.setViewPage('no-objection');
    this.permit.setViewPageData(permit);
  }

}
