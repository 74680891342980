import { Component, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SilabAuthComponent } from '../dialogs/silab-auth/silab-auth.component';
import { SilabEmailsComponent } from '../dialogs/silab-emails/silab-emails.component';
import { SilabResultDetailsComponent } from '../dialogs/silab-result-details/silab-result-details.component';
import { AuthService } from '../services/authentication/auth.service';
import { OccurancesService } from '../services/diseases/occurances.service';

@Component({
  selector: 'app-lab-detail',
  templateUrl: './lab-detail.component.html',
  styleUrls: ['./lab-detail.component.css']
})
export class LabDetailComponent implements OnInit {

  loading = false;
  result = false;
  noresult = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: any;
  public dataSourceSilab: any;
  // dat rikubva ku click ye list
  @Input() data: any;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    public occuranceService: OccurancesService) {
  }

  displayedColumns: string[] = ['endemic_disease', 'types_of_locality', 'province', 'district', 'actions'];
  displayedColumnsSilab: string[] = ['testingLab', 'labNumber','method', 'specificTest','validationDate','details'];

  ngOnInit(): void {

  }
  // data rikubva ku list
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    // @Input data prop changed
    if (changes['data'] && changes['data'].previousValue !== changes['data'].currentValue
      && changes['data'] !== undefined) {
      this.dataSource = [this.data];
      this.result = false;
      this.noresult = false;
      this.loading = false;
    }
  }

  view(data) {
    console.log(data);
    this.occuranceService.setViewPageData(data);
    this.router.navigate(['app/view-occurance']);
  }

  silabResult(element){

    // uncomment code below in production;
    const limsUniqueID = 'OUT000034';//element.limsUniqueId;
    const dialogRef = this.dialog.open(SilabAuthComponent, {
      width: '340px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.loading = true;
        this.authService.getOneSilabResult(limsUniqueID).subscribe((data: any[]) =>{
          if(data.length === 1){
            this.result = true;
            this.noresult = false;
            this.loading = false;
            this.dataSourceSilab = data;
          } else{
            this.result = false;
            this.noresult = true;
            this.loading = false;
          }
        },(err) => {
          alert(err.message);
        })
      }
    });
    
  }

  viewSilabDetails(element){
    const dialogRef = this.dialog.open(SilabResultDetailsComponent, {
      width: '550px',
      data: {
        element
      }
    });
  }

  sentMail(data){
    const dialogRef = this.dialog.open(SilabEmailsComponent , {
      width: '550px',
      data: {
        data
      }
    });
  }

}
