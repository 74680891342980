import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { Settings } from '../../../utils/settings';
import { AuthService } from '../../authentication/auth.service';
import { CarcassPermitData } from '../../../types/permits';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CarcassService {
  private settings = new Settings();
  private url: String;
  private token: String;
  private headers: any;
  private user_province: string;
  private user_district: string;

  constructor(public auth: AuthService, private http: HttpClient) {
    this.url = this.settings.getBASE_URL();
    this.token = this.auth.getSessionData().access_token;
    this.headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.token}`
    };
    this.user_province = this.auth.getSessionData().user.province;
    this.user_district = this.auth.getSessionData().user.district;
  }

  public newCarcassPermit(data: CarcassPermitData) {
    const URL = this.url + '/api/carcass-movement';
    return this.http.post(URL, data, { headers: this.headers });
  }

  public getCarcassPermits(): any {
    const URL = this.url + `/api/carcass-movement/${this.user_province}/${this.user_district}`;
    return this.http.get(URL, { headers: this.headers });
  }

  public updateCarcassPermits(permit: CarcassPermitData) {
    const URL = this.url + `/api/carcass-movement/${permit.id}`;
    return this.http.put(URL, JSON.stringify(permit), { headers: this.headers });
  }

  public deleteCarcassPermits(permit_number: string) {
    const URL = this.url + `/api/carcass-movement/${permit_number}`;
    return this.http.delete(URL, { headers: this.headers });
  }

}
