import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-livestock-permit',
  templateUrl: './livestock-permit.component.html',
  styleUrls: ['./livestock-permit.component.css']
})
export class LivestockPermitComponent implements OnInit {
  public noPermitNumber: number;
  constructor() { }

  ngOnInit() {
  }
  // badge  showing total number of no objection request
  setNOPermitNumber(num: number) {
    this.noPermitNumber = num;
  }

}
