import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LiveAnimalpermit } from 'src/app/types/permits';
import { Settings } from 'src/app/utils/settings';
import Observable from 'zen-observable-ts';
import { AuthService } from '../../authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LivestockPermitService {
  public viewpage: string;
  private settings = new Settings();
  private url: String;
  private token: String;
  private headers: any;
  private user_province: string;
  private user_district: string;
  private permit: LiveAnimalpermit;

  constructor(public auth: AuthService, private http: HttpClient) {
    this.url = this.settings.getBASE_URL();
    this.token = this.auth.getSessionData().access_token;
    this.headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.token}`
    };
    this.user_province = this.auth.getSessionData().user.province;
    this.user_district = this.auth.getSessionData().user.district;
  }

  // ! know which tab that has opened the view-pemit component
  setViewPage(page): void {
    this.viewpage = page;
  }

  getViewPage(): string {
    return this.viewpage;
  }

  setViewPageData(data: LiveAnimalpermit): void {
    this.permit = data;
  }

  getViewPageData(): LiveAnimalpermit {
    return this.permit;
  }

  newPermit(permit: LiveAnimalpermit): any {
    const URL = this.url + '/api/animal-movement-permit';
    return this.http.post(URL, permit, { headers: this.headers });
  }

  public getOutGoingPermits(): any {
    const URL = this.url + `/api/animal-movement-outgoing/${this.user_province}/${this.user_district}`;
    return this.http.get(URL, { headers: this.headers });
  }
  public getNOPermits(): any {
    const URL = this.url + `/api/animal-movement-noobjection/${this.user_province}/${this.user_district}`;
    return this.http.get(URL, { headers: this.headers });
  }

  public getIncomingPermits(): any {
    const URL = this.url + `/api/animal-movement-incoming/${this.user_province}/${this.user_district}`;
    return this.http.get(URL, { headers: this.headers });
  }

  public changePermitStatus(permit_status: string, permit_number: string): any {
    const URL = this.url + `/api/update-animal-movement-permit-status/${permit_number}/${permit_status}`;
    return this.http.put(URL, {}, { headers: this.headers });
  }

  public rejectPermit(reject_reason: string, permit_number: string): any {
    const URL = this.url + `/api/reject-animal-movement-permit/${permit_number}/${reject_reason}`;
    return this.http.put(URL, {}, { headers: this.headers });
  }

  public deletePermit(permit_number: string) {
    const URL = this.url + `/api/animal-movement-permit/${permit_number}`;
    return this.http.delete(URL, { headers: this.headers });
  }

  public addPermitAnimals(permit_number: string, animals: any[]): any {
    const URL = this.url + `/api/permit-animal/${permit_number}`;
    return this.http.post(URL, animals, { headers: this.headers });
  }

  public getPermitAnimals(permit_number: string): any {
    const URL = this.url + `/api/permit-animal/${permit_number}`;
    return this.http.get(URL, { headers: this.headers });
  }

}
