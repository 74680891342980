import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { LivestockPermitService } from 'src/app/services/permits/livestock/livestock-permit.service';
import { LiveAnimalpermit } from 'src/app/types/permits';

@Component({
  selector: 'app-incoming-permit',
  templateUrl: './incoming-permit.component.html',
  styleUrls: ['./incoming-permit.component.css']
})
export class IncomingPermitComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: any;
  private incoming_permit: LiveAnimalpermit[];
  loading = false;


  constructor(
    public router: Router,
    public permit: LivestockPermitService
  ) { }

  displayedColumns: string[] = ['permit_number', 'name', 'origin_province', 'origin_district', 'actions'];

  ngOnInit() {
    this.getIncoming();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getIncoming(): void {
    this.loading = true;
    this.permit.getIncomingPermits().subscribe((data) => {
      this.loading = false;
      this.incoming_permit = data;
      this.dataSource = new MatTableDataSource(this.incoming_permit);
      this.dataSource.paginator = this.paginator;
    }, (err) => {
      alert(err.message);
    });
  }
  refresh() {
    this.getIncoming();
  }
  openPermit(permit): void {
    this.router.navigate(['app/view-permit']);
    this.permit.setViewPage('incoming');
    this.permit.setViewPageData(permit);
  }

}
