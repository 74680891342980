import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GradingService } from 'src/app/services/grading/grading.service';
import { CattleMeatGrading } from 'src/app/types/grading';

@Component({
  selector: 'app-cattle-meat-grading',
  templateUrl: './cattle-meat-grading.component.html',
  styleUrls: ['./cattle-meat-grading.component.css']
})
export class CattleMeatGradingComponent implements OnInit {

  grading: CattleMeatGrading = {};
  public user_province: string;
  public user_district: string;
  public user_abbatoir: string;
  loading = false;

  constructor(public dialogRef: MatDialogRef<CattleMeatGradingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public auth: AuthService,
    public gradingService: GradingService
  ) {
    this.user_province = this.auth.getSessionData().user.province;
    this.user_district = this.auth.getSessionData().user.district;
    // ! user centre name in production
    this.user_abbatoir = 'Rainham';
  }

  ngOnInit() {
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  add(): void {
    this.loading = true;
    this.grading.abattoir = this.user_abbatoir;
    this.grading.province = this.user_province;
    this.grading.district = this.user_district;
    this.gradingService.newCattleGrading(this.grading).subscribe((data) => {
      this.dialogRef.close(true);
    }, (err) => {

    });
  }

}
