import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-silab-result-details',
  templateUrl: './silab-result-details.component.html',
  styleUrls: ['./silab-result-details.component.css']
})
export class SilabResultDetailsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: any;

  constructor(public dialogRef: MatDialogRef<SilabResultDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  displayedColumns: string[] = ['sampleId', 'testDataAndTime', 'validationDateAndTime', 'result'];

  close() {
    this.dialogRef.close(false);
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data.element.details);
    this.dataSource.paginator = this.paginator;
  }

}
