import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carcass',
  templateUrl: './carcass.component.html',
  styleUrls: ['./carcass.component.css']
})
export class CarcassComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
