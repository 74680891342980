import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Settings } from '../utils/settings';
import { AuthService } from './authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private settings = new Settings();
  private url: String;
  private token: String;
  private headers: any;
  private user_province: string;
  private user_district: string;

  constructor(public auth: AuthService, private http: HttpClient) {
    this.url = this.settings.getBASE_URL();
    this.token = this.auth.getSessionData().access_token;
    this.headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.token}`
    };
    this.user_province = this.auth.getSessionData().user.province;
    this.user_district = this.auth.getSessionData().user.district;
  }

  getCarcassMovement(data) {
    // tslint:disable-next-line:max-line-length
    const URL = this.url + `/api/reports/carcass-movement?origin_province=${data.origin_province}&origin_district=${data.origin_district}&start=${data.start_date}&end=${data.end_date}&destination_district=${data.destination_district}`;

    return this.http.get(URL, { headers: this.headers });
  }

  getLiveStockMovement(data) {
    // tslint:disable-next-line:max-line-length
    const URL = this.url + `/api/reports/animal-movement-permit?origin_province=${data.origin_province}&origin_district=${data.origin_district}&start=${data.start_date}&end=${data.end_date}&destination_province = ${data.destination_province}&destination_district= ${data.destination_district}`;

    return this.http.get(URL, { headers: this.headers });
  }

  getDeathDiseaseOccaeance(data) {
    // tslint:disable-next-line:max-line-length
    const URL = this.url + `/api/reports/death-disease-occurance?province=${data.province}
    &district=${data.district}
    &start=${data.start_date}
    &end=${data.end_date}`;

    return this.http.get(URL, { headers: this.headers });
  }

  getDiseaseOccaeance(data) {
    // tslint:disable-next-line:max-line-length
    const URL = this.url + `/api/reports/disease-occurance?province=${data.province}
    &district=${data.district}
    &start=${data.start_date}
    &end=${data.end_date}`;

    return this.http.get(URL, { headers: this.headers });
  }
}
