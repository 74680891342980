import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AnimalSheetComponent } from 'src/app/bottom-sheets/animal-sheet/animal-sheet.component';
import { GradingService } from 'src/app/services/grading/grading.service';
import { CattleMeatGrading, LiveCattle, OtherMeatGrade } from 'src/app/types/grading';

@Component({
  selector: 'app-meat',
  templateUrl: './meat.component.html',
  styleUrls: ['./meat.component.css']
})
export class MeatComponent implements OnInit {

  cattle = false;
  other_meat = false;
  live_cattle = false;
  picture = true;
  loading = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSourceCattle: any;
  public cattleMeatGrading: CattleMeatGrading[];
  public dataSourceOther: any;
  public otherMeatGrade: OtherMeatGrade[];
  public dataSourceLive: any;
  public liveCattleGrading: LiveCattle[];

  constructor(private _bottomSheet: MatBottomSheet, public grading: GradingService) { }
  displayedColumnsCattle: string[] = ['carcass_id', 'age', 'fat',
    'sex', 'conf', 'grade', 'date', 'actions'];
  displayedColumnsOther: string[] = ['carcass_id', 'animal', 'grade', 'date', 'actions'];
  displayedColumnsLive: string[] = ['sex', 'age', 'ystock_bcondition', 'sstock_bcondition',
    'date', 'weight', 'owner_name', 'owner_id_num', 'owner_address', 'actions'];

  ngOnInit() {
    this.picture = true;
  }

  applyFilter(filterValue: string) {
    if (this.cattle === true) {
      this.dataSourceCattle.filter = filterValue.trim().toLowerCase();
    } else if (this.other_meat === true) {
      this.dataSourceOther.filter = filterValue.trim().toLowerCase();
    } else if (this.live_cattle === true) {
      this.dataSourceLive.filter = filterValue.trim().toLowerCase();
    }
  }

  openBottomSheet(): void {
    this._bottomSheet.open(AnimalSheetComponent);
  }

  openCattle(): void {
    this.cattle = true;
    this.other_meat = false;
    this.live_cattle = false;
    this.picture = false;
    this.getCattleMeatGradingData();
  }

  openOtherMeat(): void {
    this.cattle = false;
    this.other_meat = true;
    this.live_cattle = false;
    this.picture = false;
    this.getOtherMeatGradingData();
  }

  openLiveCattle(): void {
    this.cattle = false;
    this.other_meat = false;
    this.live_cattle = true;
    this.picture = false;
    this.getLiveCattleGradingData();
  }

  getCattleMeatGradingData(): void {
    this.loading = true;
    this.grading.getCattleGrading().subscribe((data) => {
      this.loading = false;
      this.cattleMeatGrading = data;
      console.log(this.cattleMeatGrading);
      this.dataSourceCattle = new MatTableDataSource(this.cattleMeatGrading);
      this.dataSourceCattle.paginator = this.paginator;
    }, (err) => {
      alert(err.message);
    });

  }

  getOtherMeatGradingData(): void {
    this.loading = true;
    this.grading.getOtherMeatGrading().subscribe((data) => {
      this.loading = false;
      this.otherMeatGrade = data;
      this.dataSourceOther = new MatTableDataSource(this.otherMeatGrade);
      this.dataSourceOther.paginator = this.paginator;
    }, (err) => {
      alert(err.message);
    });

  }

  getLiveCattleGradingData(): void {
    this.loading = true;
    this.grading.getLiveCattleGrading().subscribe((data) => {
      this.loading = false;
      this.liveCattleGrading = data;
      this.dataSourceLive = new MatTableDataSource(this.liveCattleGrading);
      this.dataSourceLive.paginator = this.paginator;
    }, (err) => {
      alert(err.message);
    });

  }

}
