import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/authentication/auth.service';
import { SessionInfo } from '../types/session';


@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  constructor(private router: Router, public auth: AuthService) { }

  public onAuthenticated(session): void {
    this.auth.loggedIn(true);
    this.router.navigate(['/app']);
  }

  ngOnInit() {
  }

}
