import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CarcassService } from 'src/app/services/permits/carcass/carcass.service';
import { CarcassPermitData } from 'src/app/types/permits';

@Component({
  selector: 'app-view-carcass-movement',
  templateUrl: './view-carcass-movement.component.html',
  styleUrls: ['./view-carcass-movement.component.css']
})
export class ViewCarcassMovementComponent implements OnInit {

  permit: CarcassPermitData;
  loading = false;

  constructor(public dialogRef: MatDialogRef<ViewCarcassMovementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public carcassPermit: CarcassService
  ) { }

  ngOnInit() {
    this.permit = this.data.permit;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    this.loading = true;
    this.carcassPermit.updateCarcassPermits(this.permit).subscribe((data) => {
      this.loading = false;
      this.dialogRef.close(data);
    }, (err) => {
      this.dialogRef.close(err);
    });
  }

  print(): void {
    this.dialogRef.close();
  }

}
