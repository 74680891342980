import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ProfileComponent } from '../dialogs/profile/profile.component';
import { AuthService } from '../services/authentication/auth.service';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public user: string;
  public role: string;
  designation: string;

  constructor(public auth: AuthService, public dialog: MatDialog) { }

  ngOnInit() {
    this.user = `${this.auth.getSessionData().user.surname}  ${this.auth.getSessionData().user.name}`.toUpperCase();
    this.designation = this.auth.getSessionData().user.designation + ' : ' + this.auth.getSessionData().user.centre;
  }

  profile(): void {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '450px',
      data: {
        user: {
          username : this.user,
          designation : this.auth.getSessionData().user.designation.toUpperCase(),
          centre: this.auth.getSessionData().user.centre.toUpperCase()
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
