import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { DeleteComponent } from 'src/app/dialogs/delete/delete/delete.component';
import { LivestockPermitService } from 'src/app/services/permits/livestock/livestock-permit.service';
import { LiveAnimalpermit } from 'src/app/types/permits';

@Component({
  selector: 'app-outgoing-permit',
  templateUrl: './outgoing-permit.component.html',
  styleUrls: ['./outgoing-permit.component.css']
})
export class OutgoingPermitComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: any;
  private outgoin_permit: LiveAnimalpermit[];
  loading = false;



  constructor(
    public router: Router,
    public dialog: MatDialog,
    public permit: LivestockPermitService) { }
  displayedColumns: string[] = ['permit_number', 'name', 'number_of_animals', 'owner',
    'stock_inventory', 'destination_province', 'destination_district', 'status', 'actions'];

  ngOnInit() {
    this.getPermits();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getPermits(): void {
    this.loading = true;
    this.permit.getOutGoingPermits().subscribe((data) => {
      this.loading = false;
      this.outgoin_permit = data;
      this.dataSource = new MatTableDataSource(this.outgoin_permit);
      this.dataSource.paginator = this.paginator;
    }, (err) => {
      alert(err.message);
    });

  }

  delete(permit) {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '450px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result === true) {
        this.permit.deletePermit(permit.permit_number).subscribe((data) => {
          this.getPermits();
        }, (err) => {
          alert(err.message);
        });
      } else {

      }

    });
  }

  refresh() {
    this.getPermits();
  }

  newPermit(): void {
    this.router.navigate(['app/new-permit']);
  }

  viewPermit(permit): void {
    this.router.navigate(['app/view-permit']);
    this.permit.setViewPage('outgoing');
    this.permit.setViewPageData(permit);
  }
}
