import {
  NgModule
} from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MeatComponent } from './grading/meat/meat/meat.component';
import { HomeComponent } from './home/home.component';
import { LabDetailComponent } from './lab-details/lab-detail.component';
import { LabsComponent } from './labs/labs.component';
import { LandingComponent } from './landing/landing.component';
import { NotFoundComponent } from './notfound/notfound.component';
import { LivestockPermitComponent } from './permits/livestock/livestock-permit/livestock-permit.component';
import { NewPermitComponent } from './permits/livestock/new-permit/new-permit.component';
import { ViewPermitComponent } from './permits/livestock/view-permit/view-permit.component';
import { MeatPermitComponent } from './permits/meat/meat-permit/meat-permit.component';
import { AuthGuard } from './guards/auth.guard';
import { OccurancesComponent } from './disease/occurances/occurances.component';
import { ViewOccurancesComponent } from './disease/view-occurances/view-occurances.component';
import { DashComponent } from './dash/dash.component';
import { ReportsComponent } from './reports/reports.component';
import { SilabComponent } from './disease/silab/silab.component';



const appRoutes: Routes = [

  { path: '', component: LandingComponent, pathMatch: 'full' },
  {
    path: 'app', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'occurances', component: LabsComponent, canActivate: [AuthGuard] },
      { path: 'meat-grading', component: MeatComponent, canActivate: [AuthGuard] },
      { path: 'livestock-permits', component: LivestockPermitComponent, canActivate: [AuthGuard] },
      { path: 'meat-permits', component: MeatPermitComponent, canActivate: [AuthGuard] },
      { path: 'new-permit', component: NewPermitComponent, canActivate: [AuthGuard] },
      { path: 'view-permit', component: ViewPermitComponent, canActivate: [AuthGuard] },
      { path: 'view-occurance', component: ViewOccurancesComponent, canActivate: [AuthGuard] },
      { path: 'new-occurance', component: OccurancesComponent, canActivate: [AuthGuard] },
      { path: 'map', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'silab', component: SilabComponent, canActivate: [AuthGuard] },
      // { path: 'dashboard', component: DashComponent, canActivate: [AuthGuard] },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }
    ]
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { enableTracing: true })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
