import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { CarcassService } from 'src/app/services/permits/carcass/carcass.service';
import { CarcassPermitData } from 'src/app/types/permits';
import { Locations } from 'src/app/utils/locations';

@Component({
  selector: 'app-carcass-movement',
  templateUrl: './carcass-movement.component.html',
  styleUrls: ['./carcass-movement.component.css']
})
export class CarcassMovementComponent implements OnInit {

  permitData: CarcassPermitData = {
    permit_number: `DVS-${new Date().getFullYear()}-${Math.floor(Math.random() * 10000) + 1}`,
    establishment: '',
    abattoir_number: '',
    district: '',
    to_establishment: '',
    to_district: '',
    purpose: '',
    product_description: '',
    quality: '',
    net_mass: '',
    production_date: '',
    dispatch_date: '',
    vehicle_reg_num: '',
    ambient_temp: null,
    movement_period: '',
    createdby: '',
    permit_province: '',
    permit_district: ''
  };
  loading = false;
  // province and district data
  public centres = new Locations();
  public destProvince: any[];
  public destDistrict: any[];

  constructor(public dialogRef: MatDialogRef<CarcassMovementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public permit: CarcassService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.destDistrict = this.centres.getDistricts();
    this.destProvince = this.centres.getProvinces();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  add(): void {
    this.loading = true;
    this.permitData.createdby = `${this.auth.getSessionData().user.surname}  ${this.auth.getSessionData().user.name}`;
    this.permitData.permit_province = this.auth.getSessionData().user.province;
    this.permitData.permit_district = this.auth.getSessionData().user.district;
    this.permit.newCarcassPermit(this.permitData).subscribe((data) => {
      this.dialogRef.close(true);
      console.log(data);
    }, (err) => {
      this.dialogRef.close(err);
      console.log(err);
    });

  }

}
