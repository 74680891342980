import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/authentication/auth.service';
import { SessionInfo } from '../types/session';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  readonly KEY_ENTER: number = 13;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onAuthenticated = new EventEmitter<SessionInfo>();

  @Output() error = { message: null };
  loading = false;
  errorr = false;
  session: SessionInfo;
  auth = { username: null, password: null };

  constructor(private route: ActivatedRoute, public authService: AuthService) {
  }

  onNext(event: any): void {
    this.loading = true;
    this.errorr = false;


    // takuita rogu in yacho pano
    this.authService.login(JSON.stringify(this.auth)).subscribe((response) => {
      this.session = response;
      this.authService.setSessionData(this.session);
      this.onAuthenticated.emit(this.session);
      this.loading = false;
    }, (err) => {
      this.errorr = true;
      this.loading = false;
      this.error.message = 'Check Credential And Try Again';
    });
  }

  ngOnInit(): void {

  }

}
