import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { CarcassMovementComponent } from 'src/app/dialogs/carcass-movement/carcass-movement.component';
import { DeleteComponent } from 'src/app/dialogs/delete/delete/delete.component';
import { ViewCarcassMovementComponent } from 'src/app/dialogs/view-carcass-movement/view-carcass-movement.component';
import { CarcassService } from 'src/app/services/permits/carcass/carcass.service';
import { CarcassPermitData } from '../../../types/permits';

@Component({
  selector: 'app-meat-permit',
  templateUrl: './meat-permit.component.html',
  styleUrls: ['./meat-permit.component.css']
})
export class MeatPermitComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: any;
  carcass_permit: CarcassPermitData[];
  loading = false;

  constructor(public dialog: MatDialog, public carcass: CarcassService) { }
  displayedColumns: string[] = ['permit_number', 'name', 'abattoir_name', 'to_establishment', 'actions'];

  ngOnInit() {
    this.getCarcasPermits();
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  refresh(): void {
    this.getCarcasPermits();
  }
  // new carcas movement permit
  new(): void {
    const dialogRef = this.dialog.open(CarcassMovementComponent, {
      width: '450px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result === true) {
        this.getCarcasPermits();
      } else {

      }

    });
  }

  // view permit
  viewPermit(permit): void {
    const dialogRef = this.dialog.open(ViewCarcassMovementComponent, {
      width: '450px',
      data: {
        permit
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result === true) {
        this.getCarcasPermits();
      } else {
        console.log(result);
      }

    });
  }

  // detele permit
  delete(permit): void {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '450px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result === true) {
        this.carcass.deleteCarcassPermits(permit.permit_number).subscribe((data) => {
          console.log(data);
        }, (err) => {
          alert(err.message);
        });
        this.getCarcasPermits();
      } else {
        console.log(result);
      }

    });
  }

  getCarcasPermits(): void {
    this.loading = true;
    this.carcass.getCarcassPermits().subscribe((data) => {
      this.loading = false;
      this.carcass_permit = data;
      this.dataSource = new MatTableDataSource(this.carcass_permit);
      this.dataSource.paginator = this.paginator;
    }, (err) => {
      alert(err.message);
    });
  }

}
