import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { OccurancesService } from 'src/app/services/diseases/occurances.service';
import { Locations } from 'src/app/utils/locations';
import { Occurance } from '../../types/occurance';

@Component({
  selector: 'app-occurances',
  templateUrl: './occurances.component.html',
  styleUrls: ['./occurances.component.css']
})
export class OccurancesComponent implements OnInit {
  occurance: Occurance = {};
  public loading = false;
  private user_province: string;
  private user_district: string;
  private user: string;
  private user_rank: string;

  public destProvince: any[];
  public destDistrict: any[];
  public centres = new Locations();

  constructor(
    public router: Router,
    public occuranceService: OccurancesService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.user = `${this.auth.getSessionData().user.name}  ${this.auth.getSessionData().user.surname}`;
    this.user_rank = this.auth.getSessionData().user.designation;
    this.destDistrict = this.centres.getDistricts();
    this.destProvince = this.centres.getProvinces();
    this.getDistrictsInProvince();
  }

  cancel(): void {
    this.router.navigate(['app/occurances']);
  }

  save(): void {
    this.loading = true;
    this.occurance.originator = this.user;
    this.occurance.reporter_rank = this.user_rank;
    this.occurance.institution = this.auth.getSessionData().user.centre;
    this.occurance.country = 'Zimbabwe';
    this.occuranceService.newOccurance(this.occurance).subscribe(
      (data) => {
        this.loading = false;
        this.router.navigate(['app/occurances']);
      }, (err) => {
        this.loading = false;
        alert(err.message);
      }
    );

  }

  getDistrictsInProvince() : any[] {
    let newData = [];
    for(let i = 0 ; i < this.destDistrict.length ; i++){
      if(this.destDistrict[i].province == this.occurance.province){
        newData.push(this.destDistrict[i]);
      };
    }
    return newData;
  }
}
