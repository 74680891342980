import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Rx from 'rxjs';

import { Settings } from '../../utils/settings';
import { SessionInfo } from '../../types/session';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private settings = new Settings();
  private url: String;
  private sessionData: SessionInfo;
  logn: boolean;
  silabToken: any;

  constructor(private http: HttpClient) {
    this.url = this.settings.getBASE_URL();
  }

  public login(data): any {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    const loginURL = this.url + '/api/auth/login';

    return this.http.post(loginURL, data, { headers });

  }
  public setSessionData(data: SessionInfo) {
    this.sessionData = data;
  }
  public getSessionData(): SessionInfo {
    return this.sessionData;
  }
  loggedIn(bool: boolean) {
    this.logn = bool;
  }
  isLoggedIn(): boolean {
    return this.logn;
  }
  
  // Silab services here

  public silaBAuth(data){
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    const loginURL = 'https://demo.izs.it/silabfa-zw-api/authenticate';

    return this.http.post(loginURL, data, { headers })
  }
  public setSilabToken(silabToken){
    this.silabToken = silabToken; 
  }
  public getSilabToken(){
    return this.silabToken;
  }

  public getOneSilabResult(limsUniqueID) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.getSilabToken()}`
    };
    const URL = 'https://demo.izs.it/silabfa-zw-api/v1/SilabZwLimsResults?limsId=LIMS ' + limsUniqueID;
    return this.http.get(URL, { headers: headers });

  }
}
