import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-permit',
  templateUrl: './permit.component.html',
  styleUrls: ['./permit.component.css']
})
export class PermitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
