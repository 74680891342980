import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { OccurancesService } from 'src/app/services/diseases/occurances.service';
import { ReportsService } from 'src/app/services/reports.service';
import { Locations } from 'src/app/utils/locations';

@Component({
  selector: 'app-disease-report',
  templateUrl: './disease-report.component.html',
  styleUrls: ['./disease-report.component.css']
})
export class DiseaseReportComponent implements OnInit {
  // province and district data
  public centres = new Locations();
  public destProvince: any[];
  public destDistrict: any[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: any;
  public data = {province : null,
                 district : null,
                 start_date: null,
                 end_date: null};
  loading = false;

  constructor(
    public router: Router,
    public occuranceService: OccurancesService,
    public reportServ: ReportsService) {
  }

  displayedColumns: string[] = ['endemic_disease', 'types_of_locality', 'province', 'district', 'actions'];

  ngOnInit() {
    this.destDistrict = this.centres.getDistricts();
    this.destProvince = this.centres.getProvinces();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  view(data) {
    console.log(data);
    this.occuranceService.setViewPageData(data);
    this.router.navigate(['app/view-occurance']);
  }

  run(): void {
    this.loading = true;
    this.reportServ.getDiseaseOccaeance(this.data).subscribe((data) => {
      this.loading = false;
      console.log(data);
      this.dataSource = data;
      this.dataSource.paginator = this.paginator;
    }, (err) => {

    });
  }

}
