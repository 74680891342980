import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CattleMeatGrading, LiveCattle, OtherMeatGrade } from 'src/app/types/grading';
import { Settings } from 'src/app/utils/settings';
import { AuthService } from '../authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GradingService {

  private settings = new Settings();
  private url: String;
  private token: String;
  private headers: any;
  private user_province: string;
  private user_district: string;
  private user_centre: string;

  constructor(public auth: AuthService, private http: HttpClient) {
    this.url = this.settings.getBASE_URL();
    this.token = this.auth.getSessionData().access_token;
    this.headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.token}`
    };
    this.user_province = this.auth.getSessionData().user.province;
    this.user_district = this.auth.getSessionData().user.district;
    this.user_centre = 'Rainham';
    // ! use user center in productio and remove hard code iyi babamunini
  }

  public newCattleGrading(data: CattleMeatGrading) {
    const URL = this.url + '/api/cattle-grading';
    return this.http.post(URL, data, { headers: this.headers });
  }

  public getCattleGrading(): any {
    const URL = this.url + `/api/cattle-grading/${this.user_province}/${this.user_district}/${this.user_centre}`;
    return this.http.get(URL, { headers: this.headers });
  }

  public otherMeatGrading(data: OtherMeatGrade) {
    const URL = this.url + '/api/other-cattle-grading';
    return this.http.post(URL, data, { headers: this.headers });
  }

  public getOtherMeatGrading(): any {
    const URL = this.url + `/api/other-cattle-grading/${this.user_province}/${this.user_district}/${this.user_centre}`;
    return this.http.get(URL, { headers: this.headers });
  }

  public newLiveCattleGrading(data: LiveCattle) {
    const URL = this.url + '/api/live-cattle-grading';
    return this.http.post(URL, data, { headers: this.headers });
  }

  public getLiveCattleGrading(): any {
    const URL = this.url + `/api/live-cattle-grading/${this.user_province}/${this.user_district}/${this.user_centre}`;
    return this.http.get(URL, { headers: this.headers });
  }

}
