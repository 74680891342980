import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disease',
  templateUrl: './disease.component.html',
  styleUrls: ['./disease.component.css']
})
export class DiseaseComponent implements OnInit {

  public dieseaseDashData = [];

  constructor() {
    this.dieseaseDashData = [
      {
        disease: 'Atherosclerosis',
        cum_case: 23,
        new_case: 45,
        cum_death: 278,
        new_death: 758
      }, {
        disease: 'Babesiosis',
        cum_case: 23,
        new_case: 42,
        cum_death: 278,
        new_death: 78
      }, {
        disease: 'Heartwater',
        cum_case: 23,
        new_case: 4,
        cum_death: 278,
        new_death: 78
      }, {
        disease: 'Foot and Mouth',
        cum_case: 23,
        new_case: 4,
        cum_death: 278,
        new_death: 78
      }, {
        disease: 'Anthrax',
        cum_case: 23,
        new_case: 4,
        cum_death: 278,
        new_death: 78
      }, {
        disease: 'Rabies',
        cum_case: 23,
        new_case: 4,
        cum_death: 278,
        new_death: 78
      }, {
        disease: 'Newcastle',
        cum_case: 23,
        new_case: 4,
        cum_death: 278,
        new_death: 78
      }, {
        disease: 'Anaplasmosis',
        cum_case: 23,
        new_case: 4,
        cum_death: 278,
        new_death: 78
      }
    ];
  }

  ngOnInit() {
  }

}
